.article-body {
    overflow: hidden;
    margin-top: -60px;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }
}

.article-header {
    .a2a_kit {
        svg path {
            fill: $primary;
            transition: fill 150ms ease-in-out;
        }

        a:hover {
            .a2a_svg {
                opacity: 1;
            }

            svg path {
                fill: $secondary;
            }
        }
    }
}

.article-header__image {
    .embed-responsive {
        &:hover {
            svg {
                opacity: 0.6;
            }
        }
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: calc(50vw - 60px);
        height: calc(50vw - 60px);
        max-width: 200px;
        max-height: 200px;
        transition: opacity 150ms ease-in-out;

        @include media-breakpoint-up(md) {
            width: calc(25vw - 60px);
            height: calc(25vw - 60px);
        }
    }
}

.article-header__rating {
    &:hover {
        .rating-tooltip {
            opacity: 1;
            visibility: visible;
            transition-delay: 150ms;
        }
    }

    .rating-tooltip {
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms, visibility 300ms;
    }
}

.article-content {
    h1, h2, h3, h4, h5, h6 {
        &:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
            font-size: inherit;
            margin-bottom: 0;
            line-height: inherit;
            font-weight: 700;
            letter-spacing: inherit;
        }
    }
}