﻿.modal {
    padding: 0;
    background: transparent;
    border: none;
    color: inherit;
    width: 100vw;

    &[open] {
        display: flex;
        align-items: center;
    }

    &.show {
        &::backdrop {
            opacity: 0.9;
        }
    }

    &::backdrop {
        background-color: #1D4671;
        opacity: 0;
        transition: opacity 300ms ease;
    }

    // polyfill
    &[role="dialog"] {
        &.show {
            & + .backdrop {
                opacity: 0.9;
            }
        }

        & + .backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1040;
            background-color: #1D4671;
            opacity: 0;
            transition: opacity 300ms ease;
        }
    }

    .close {
        float: none;
        color: #fff;
        border: 3px solid currentColor;
        opacity: 1 !important;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
    }
}

.modal-dialog {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    pointer-events: unset;
}

.modal-content {
    max-height: calc(100vh - 100px);
    border-radius: 0;
    overflow: auto;
}

.modal-iframe-wrapper {
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
        overflow: hidden;
    }
}

.modal--video {
    .modal-dialog {
        width: calc(100vw - 30px);
        max-width: 1000px;
    }
}