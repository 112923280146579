.filters {
    position: relative;
    z-index: $zindex-dropdown;
}

.btn-toggle-filter-group {
    display: block;
    padding: 0.75rem 1.5rem;
    background-color: transparent;
    border: none;
    font-weight: 700;
    color: inherit;

    @include media-breakpoint-up(md) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    &:hover {
        @media (pointer: fine) {
            color: $primary;
        }
    }

    &.active {
        color: $primary;
    }

    svg {
        position: relative;
        top: 0.125rem;
        margin-left: 0.5rem;
    }
}

.filter-group {
    @include media-breakpoint-up(md) {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        border-bottom: 2px solid $secondary;
    }

    &.show {
        z-index: 1;
    }

    label {
        font-weight: bold;
        cursor: pointer;

        &:hover {
            @media (pointer: fine) {
                color: $primary;
            }
        }
    }

    input[type="checkbox"] {
        position: absolute;
        left: -999em;
        opacity: 0;

        &:checked + label {
            color: $primary;
        }
    }
}

.btn-remove-tag {
    background-color: #fafafa;
    border: 1px solid #cbcbcb;
    transition: $btn-transition;

    &:hover {
        background-color: $light;
        border-color: $primary;
        color: $primary;
    }
}