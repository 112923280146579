.page-modal {
    padding-right: 0;
    min-height: 0;

    .site-header,
    .site-footer {
        display: none;
    }

    .page-content {
        padding-top: 0;
    }
}
