.wbb-compare__image {
    .embed-responsive {
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            background: $light;
            border-radius: 50%;
            width: 72%;
            height: 72%;
            transform: translate(-50%,-50%);
        }
    }

    .embed-responsive-item {
        z-index: 1;
    }
}

.wbb-compare__modal {
    padding: 2rem 0 3rem;

    &[open] {
        display: block;
    }

    .modal-dialog {
        max-width: 1100px;
    }

    .modal-content {
        max-height: none;
    }
}

.wbb-compare__table {
    margin: 0 -3rem -3rem 0;

    th,
    td {
        vertical-align: top;
        padding: 0 3rem 3rem 0;
    }

    thead td,
    tbody th {
        width: 20%
    }

    thead th,
    tbody td {
        width: 40%;
    }
}
