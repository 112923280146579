.page-content {
    position: relative;
    padding-top: 10rem;
    background-color: #fff;

    @media print {
        padding-top: 2.5rem;
    }

    .display-1,
    .display-2,
    .display-3,
    .display-4 {
        color: $primary;
    }
}

img.lazy {
    transition: opacity 150ms linear;

    &:not(.loaded) {
        opacity: 0;
    }
}

.wedge-top,
.wedge-bottom {
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        background-color: inherit;
    }
}

.wedge-top {
    @supports (-webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%)) or (clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%)) {
        margin-top: 5vw;

        &::before {
            bottom: 100%;
            height: 5vw;
            clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%);
        }
    }
}

.wedge-bottom {
    @supports (-webkit-clip-path: polygon(100% 0, 100% 100%, 0 0, 100% 0)) or (clip-path: polygon(100% 0, 100% 100%, 0 0, 100% 0)) {
        margin-bottom: 5vw;

        &::after {
            top: 100%;
            height: 5vw;
            clip-path: polygon(100% 0, 100% 100%, 0 0, 100% 0)
        }
    }
}

.usda-aa-badge {
    position: absolute;
    right: 1%;
    bottom: 9%;
    width: 15%;

    .scroll-reveal--reveal & {
        animation-name: pulse;
        animation-duration: 2500ms;
        animation-iteration-count: infinite;
        animation-delay: 1000ms;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.25);
    }

    50% {
        transform: scale(1);
    }
}

.social a {
    transition: color 150ms ease-in-out;
}

.article-grid__link {
    transition: color 150ms ease-in-out;

    &:hover {
        text-decoration: none;

        .embed-responsive-item {
            transform: scale(1.1);
        }
    }

    .embed-responsive-item {
        will-change: transform;
        transition: transform 2000ms ease;
    }
}