@font-face {
    font-family: 'Tiempos Headline';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/TiemposHeadline-Semibold.woff') format('woff');
}

html {
    scroll-behavior: smooth;

    @media (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }
}

body {
    letter-spacing: 0.01em;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    @include media-breakpoint-down(sm) {
        font-size: 20/16 * 1rem;
    }
}
    
.page {
    @include media-breakpoint-up(md) {
        min-height: 100vh;
        padding-right: 68px;
    }

    @media print {
        padding-right: 0;
    }
}

.container-fluid {
    max-width: 1220px + $grid-gutter-width * 2;

    @media print {
        max-width: none;
        padding-right: 0;
        padding-left: 0;
    }
}

.font-weight-semibold {
    font-weight: 600;
}

.min-vh-50 {
    min-height: 50vh;
}

.min-vh-75 {
    min-height: 75vh;
}

.fit-cover {
    object-fit: cover;
}

.column-count-2,
.column-count-md-3 {
    column-count: 2;

    li {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }
}

.column-count-md-3 {
    @include media-breakpoint-up(md) {
        column-count: 3;
    }
}

.embed-responsive-3by2 {
    &::before {
        padding-top: 66.667%;
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.display-1,
.display-2,
.display-3,
.display-4 {
    letter-spacing: -0.01em;
}

.display-1,
.display-2,
.display-3,
.display-4 {
    font-family: $font-family-serif;
}

.display-1 {
    @include media-breakpoint-down(lg) {
        font-size: calc(5.5rem + 0.5 * ((100vw - 375px) / 50));
    }
}

.display-2 {
    @include media-breakpoint-down(lg) {
        font-size: calc(4.25rem + 1.25 * ((100vw - 375px) / 50));
    }
}

.display-1,
.display-2 {
    @include media-breakpoint-down(sm) {
        font-size: calc(3.125rem + 1.125 * ((100vw - 375px) / 50));
    }
}

.display-3 {
    @include media-breakpoint-down(lg) {
        font-size: calc(3.125rem + 1.125 * ((100vw - 375px) / 50));
    }
}

.display-4 {
    @include media-breakpoint-down(lg) {
        font-size: calc(2rem + 0.5 * ((100vw - 375px) / 50));
    }
}

.overflow-md-visible {
    @include media-breakpoint-up(md) {
        overflow: visible !important;
    }
}