.btn {
    text-transform: uppercase;
    letter-spacing: 0;

    &:focus {
        box-shadow: none;
    }
}

.btn-secondary {
    color: #fff;

    &.disabled,
    &:disabled {
        color: #fff;
    }

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: #fff;
        background-color: $info;
        border-color: $info;
    }
}

.btn--arrow-link {
    position: relative;
    display: inline-flex;
    padding: 0;
    color: $primary;
    background-color: transparent;
    border: none;
    text-align: left;

    &:hover,
    &:focus,
    &:active {
        color: $primary;

        .btn__text::after {
            transform: scaleX(1);
        }
    }

    &:hover,
    &:focus {
        &::before {
            background-position: left center;
            transition-delay: 500ms;
        }

        &::after {
            background-position: 1.25rem center;
            transition-delay: 0ms;
        }
    }

    &::before,
    &::after {
        content: '';
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24.303 14.303'%3e%3cpath d='M7.151,0l-1.3,1.3,4.922,4.922H-10V8.08H10.774L5.851,13l1.3,1.3L14.3,7.151Z' transform='translate(10.001)' fill='%2391cfe6' /%3e%3c/svg%3e") no-repeat left center;
        width: 1.25rem;
        height: 1.325em;
        flex-shrink: 0;
        transition: background-position 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        background-position: -1.25rem center;
        transition-delay: 0ms;
    }

    &::after {
        margin-left: 1rem;
        transition-delay: 500ms;
    }

    .btn__text {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            border-bottom: 2px solid $secondary;
            transform: scaleX(0);
            transform-origin: left bottom;
            transition: transform 750ms ease-in-out;
        }
    }
}

.btn-print {
    display: flex;
    align-items: center;
    transition: color 150ms ease-in-out;

    &:hover {
        text-decoration: none;
        color: $secondary;
    }
}