@media print {
	button,
	input,
	select,
	textarea,
	video,
	nav,
    form,
	.form,
	.form-group,
	.btn,
	.breadcrumb {
		display: none !important;
	}

	[class*="text-"] {
		color: $black !important;
	}

	.d-print-none {
		display: none !important;
	}

	.d-print-block {
		display: block !important;
	}

	.d-print-inline-block {
		display: inline-block !important;
	}

	.d-print-inline {
		display: inline !important;
	}

	.d-print-flex {
		display: flex !important;
	}

	.text-print-right {
		text-align: right !important;
	}

	.text-print-left {
		text-align: left !important;
	}

	.text-print-center {
		text-align: center !important;
	}
	
	@for $i from 1 through $grid-columns {
		.col-print-#{$i} {
			flex: 0 0 #{$i / $grid-columns * 100%};
			max-width: #{$i / $grid-columns * 100%};
		}
	}

    .flex-row-print-reverse {
        flex-direction: row-reverse !important;
    }

    .page-break-inside-print-avoid {
        page-break-inside: avoid;
    }
}