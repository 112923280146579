.tns-outer {
    button[data-action="start"],
    button[data-action="stop"] {
        display: none;
    }
}

.tns-ovh {
    margin: 0 #{$grid-gutter-width / -2};

    .tns-item {
        padding: 0 #{$grid-gutter-width / 2};
    }
}
    
.tns-nav,
.tns-controls {
    button {
        padding: 0;
        border: none;
        background: none;
    }    
}

.tns-nav {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    button {
        background-color: transparent;
        border: 2px solid $primary;
        padding: 0;
        width: 12/16 * 1rem;
        height: 12/16 * 1rem;
        border-radius: 50%;
        margin: 0 0.5rem;
        transition: $btn-transition;

        &:hover,
        &.tns-nav-active {
            background-color: $primary;
        }

        &:focus {
            outline: none;
        }
    }
}

.tns-controls {
    &:focus {
        outline: none;

        button {
            background-color: $info;
        }
    }

    button {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: $secondary url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='34' height='22' viewBox='0 0 43 27.094'%3e%3cpath id='btn-arrow' d='M22.453,0,19.99,2.463l9.324,9.324H-7v3.519H29.315L19.99,24.63l2.463,2.463L36,13.547Z' transform='translate(36 27.094) rotate(180)' fill='%23fff'/%3e%3c/svg%3e") no-repeat center;
        text-indent: -999em;
        transform: translateY(-50%);
        transition: $btn-transition;

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
        }

        & + button {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='34' height='22' viewBox='0 0 43 27.094'%3e%3cpath d='M22.453,0,19.99,2.463l9.324,9.324H-7v3.519H29.315L19.99,24.63l2.463,2.463L36,13.547Z' transform='translate(7 0)' fill='%23fff'/%3e%3c/svg%3e");

            @include media-breakpoint-up(sm) {
                left: auto;
                right: 0;
            }
        }

        &:hover {
            background-color: $info;
        }
    }
}