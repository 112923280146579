.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    padding: 15px;
    height: 100px;
    background-color: $primary;
    font-size: 1rem;

    @include media-breakpoint-up(md) {
        position: absolute;
        padding: 1.5rem;
        height: auto;
        background-color: transparent;
    }

    @media print {
        position: relative;
        display: block;
        margin-bottom: 1.5rem;
        padding: 0 0 0 5px;
    }

    &::after {
        @include media-breakpoint-up(md) {
            content: '';
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: 68px;
            background-color: $primary;
        }

        @media print {
            content: none;
        }
    }

    a {
        color: inherit;
    }
}

.navbar-brand {
    width: 140px;

    @include media-breakpoint-up(md) {
        width: 160px;
    }
}

.navbar-toggle {
    position: fixed;
    top: 24px;
    right: 12px;
    z-index: $zindex-fixed;
    background-color: transparent;
    border: 3px solid currentColor;
    width: 55px;
    height: 55px;
    padding: 0;
    cursor: pointer;

    @include media-breakpoint-up(md) {
        width: 44px;
        height: 44px;
        transition: transform 500ms ease-in-out;
    }

    @media print {
        display: none !important;
    }

    &:focus {
        outline: none;
    }

    &:not(.collapsed) {
        @include media-breakpoint-up(md) {
            transform: translateX(-294px);
        }

        span {
            transform: translateY(10px) rotate(45deg);

            &::before {
                opacity: 0;
                transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
            }

            &::after {
                transform: translateY(-20px) rotate(-90deg);
            }
        }
    }

    span {
        position: relative;
        display: block;
        width: 65%;
        height: 3px;
        margin-top: -20px;
        background-color: currentColor;
        transition: transform 250ms ease;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 20px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: inherit;
            transition: transform 250ms ease;
        }

        &::before {
            top: 10px;
            transition: transform 250ms ease, opacity 250ms ease;
        }
    }
}

.site-nav {
    position: fixed;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: #{$zindex-fixed - 10};
    display: flex;
    transition: visibility 150ms ease;

    @include media-breakpoint-up(md) {
        top: 0;
        left: auto;
        transition: transform 500ms ease-in-out;
    }

    @media print {
        display: none !important;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: $primary;
        transition: transform 150ms ease;
        transform-origin: top center;

        @include media-breakpoint-up(md) {
            transform: none !important;
            transition: none !important;
        }
    }

    &::after {
        @include media-breakpoint-up(md) {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 92px;
            background: $primary;
        }
    }

    &.collapsed {
        visibility: hidden;
        transition-delay: 250ms;

        @include media-breakpoint-up(md) {
            visibility: visible;
            transition-delay: 0ms;
            transform: translateX(100%); // ie11 doesn't like calc() inside of translate()
            transform: translateX(calc(100% - 68px));
        }

        &::before {
            transform: scaleY(0);
            transition-delay: 150ms;
        }
    }

    a {
        transition: color 150ms ease-in-out;

        &:hover {
            text-decoration: none;
            color: $secondary;
        }
    }
}

.site-nav__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 15px;
    height: 100%;
    overflow: auto;

    @include media-breakpoint-up(md) {
        padding: 130px 30px 60px;

        @media (min-height: 768px) {
            padding-top: 180px;
        }
    }
}

.site-nav__menu,
.site-nav__footer {
    transition: opacity 500ms ease-in-out;

    .collapsed & {
        opacity: 0;
    }
}

.site-nav__menu {
    padding: 30px 0 50px;
    font-size: 24/16 * 1rem;
    font-weight: 500;

    @include media-breakpoint-up(md) {
        padding: 0 70px 50px 20px;
    }

    li {
        margin-bottom: 1em;
        transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;

        @for $i from 0 through 4 {
            &:nth-child(#{$i + 1}) {
                transition-delay: $i * 50ms;
            }
        }

        .collapsed & {
            opacity: 0;
            transform: translateY(-10px);
            
            @include media-breakpoint-up(md) {
                transform: translateX(50px);
            }

            @for $i from 0 through 4 {
                &:nth-child(#{5 - $i}) {
                    transition-delay: $i * 50ms;
                }
            }
        }
    }
}