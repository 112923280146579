.related-content-slider {
    .related-content-slide:not(:first-child):not(.tns-item) {
        display: none;
    }

    .tns-item {
        transition-duration: 0ms !important;
        transition-delay: 500ms !important;

        @media print {
            &:not(.tns-slide-active) {
                display: none !important;
            }
        }
    }

    .tns-fadeOut {
        z-index: 0;

        .related-content-slide__image {
            transform: translateX(-25px);
            opacity: 0;
        }

        &.tns-fadeToRight {
            .related-content-slide__image {
                transform: translateX(25px);
            }
        }
    }

    .tns-fadeIn {
        .related-content-slide__image {
            opacity: 1;
            transition-delay: 500ms;
        }

        .related-content-slide__content > div {
            opacity: 1;
            transform: translateY(0);
            transition-duration: 750ms;
            transition-delay: 750ms;
        }
    }

    .tns-controls {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }

        button {
            transform: none;

            &:first-child {
                top: 15%;
                right: calc(70% - 15px);
                left: auto;

                @include media-breakpoint-up(lg) {
                    right: calc(65% - 15px);
                }

                @include media-breakpoint-up(xl) {
                    right: calc(60% - 15px);
                }
            }

            &:last-child {
                top: auto;
                right: -7.5px;
                bottom: 15%;

                @media (min-width: 1400px) {
                    right: -15px;
                }
            }
        }
    }

    .tns-nav {
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(md) {
            position: absolute;
            right: -0.5rem;
            bottom: 0;
        }
    }
}

.related-content-slide__image {
    opacity: 0;
    transition: opacity 500ms, transform 500ms;
}

.related-content-slide__content {
    position: relative;
    background-color: rgba(#fff,0.8);
    margin-top: -4rem;
    padding-top: 4rem;

    @include media-breakpoint-up(md) {
        position: absolute;
        top: 9%;
        right: 15px;
        bottom: 9%;
        width: 70%;
    }

    @include media-breakpoint-up(lg) {
        width: 65%;
    }

    @include media-breakpoint-up(xl) {
        width: 60%;
    }

    & > div {
        opacity: 0;
        transform: translateY(25px);
        transition: opacity 500ms, transform 500ms;
    }

    p {
        min-height: 4.5em;
    }
}