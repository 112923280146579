.content-callout {
    overflow: hidden;

    @include media-breakpoint-up(md) {
        overflow: visible;
    }
}

.content-callout__img-back {
    width: calc(100% + 30px);
    margin: 0 -15px -5rem;

    @include media-breakpoint-up(md) {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    @include media-breakpoint-up(lg) {
        width: 66.6667%;
        margin: 0;
    }
}

.content-callout__img-front {
    position: absolute;
    top: 35vw;
    left: 2rem;
    z-index: 1;
    width: calc(75% - 4rem);
    will-change: transform;

    @include media-breakpoint-up(sm) {
        width: calc(60% - 4rem);
    }

    @include media-breakpoint-up(md) {
        left: 4rem;
        width: calc(65% - 8rem);
    }

    @include media-breakpoint-up(lg) {
        top: auto;
        bottom: -4rem;
        left: 7%;
        width: 39.667%;
    }

    @media print {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        width: 50%;
        flex: 0 0 50%;
        transform: none !important;
    }
}

.content-callout__content {
    position: relative;
    background-color: rgba(#fff,0.8);

    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 20%;
        right: 15px;
        bottom: -3rem;
        left: 41.667%;
    }

    @media print {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        padding: 0 0 0 1.5rem !important;
        margin: 0 !important;
        flex: 0 0 50%;

        * {
            padding: 0 !important;
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
    }
}

.content-callout--reverse {
    .content-callout__img-back {
        @include media-breakpoint-up(lg) {
            margin-left: auto;
        }
    }

    .content-callout__img-front {   
        right: 2rem;
        left: auto;

        @include media-breakpoint-up(lg) {
            right: 7%;
        }
    }

    .content-callout__content {    
        @include media-breakpoint-up(lg) {
            right: 41.667%;
            left: 15px;
        }
    }
}

#social-section {
    position: relative;
    width: 100%;

   /* @include media-breakpoint-up(md) { //if need to expand over menu
        z-index: 1030;
        width: calc(100% + 68px);
    }*/

    .row-social-section {
        .col {
            height: 460px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

.social-callout {
    max-width: 540px;
    top: 0;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
    height: calc(50% + (460px / 6));
    margin: auto;

    @include media-breakpoint-up(md) {
        width: 540px;
    }

    &__bg {
        background-color: rgba(#fff,0.8);
    }

    .display-4 {
        @include media-breakpoint-down(sm) {
            font-size: 1.4em;
        }
    }
}