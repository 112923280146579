.scroll-reveal {
    &:not(.scroll-reveal--reveal) {
        .scroll-reveal__image,
        .scroll-reveal__content {
            opacity: 0;

            @media print {
                opacity: 1 !important;
                transform: none !important;
            }
        }

        .scroll-reveal__image {
            transform: translateX(-25px);
        }

        .scroll-reveal__content {
            transform: translateY(25px);
        }

        .flex-row-reverse {
            .scroll-reveal__image {
                @include media-breakpoint-up(sm) {
                    transform: translateX(25px);
                }
            }
        }
    }
}

.scroll-reveal__image,
.scroll-reveal__content {
    transition: opacity 500ms ease, transform 500ms ease;
}

.scroll-reveal__content {
    transition-delay: 500ms;
}
