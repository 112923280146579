.footer-cows {
    opacity: 0.55;
}

.site-footer {
    h2, h3, h4, h5, h6 {
        &:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
            font-size: inherit;
        }
    }
}