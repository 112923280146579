input[type="email"].form-control,
input[type="search"].form-control,
input[type="text"].form-control,
textarea.form-control {
    border-bottom-color: $secondary;
    background-color: $input-background-color;

    &:focus {
        border-color: $input-border-color;
        border-bottom-color: $primary;
        box-shadow: none;
    }
}

input[type="search"].form-control {
    &:not(.mdc-text-field__input):not(.mdc-text-field__textarea):not(:placeholder-shown) {
        border-bottom-color: $primary;
    }
}

.form-group {
    &.has-error {
        label,
        .form-control-label {
            color: $danger;
        }

        .form-control {
            border-bottom-color: $danger;
        }
    }
}

.mdc-text-field {
    position: relative;

    &.has-focus,
    &.has-value {
        .mdc-floating-label {
            top: 0.25rem;
            font-size: 11/16 * 1rem;
        }

        .mdc-text-field__input,
        .mdc-text-field__textarea {
            border-bottom-color: $primary;
        }
    }
}

.mdc-floating-label {
    position: absolute;
    top: 1rem;
    left: $input-padding-x;
    z-index: 1;
    color: $input-placeholder-color;
    margin-bottom: 0;
    font-size: 1rem;
    cursor: text;
    transition: top 150ms ease-in-out, font-size 150ms ease-in-out, color 150ms ease-in-out;
    will-change: top, font-size, color;
}

.mdc-text-field__input,
.mdc-text-field__textarea {
    position: relative;
}

.mdc-text-field__input {
    padding-bottom: 0;
}

.mdc-text-field__textarea {
    min-height: 12rem;
    padding-top: 1.25rem;
}

.custom-checkbox {
    .custom-control-label {
        cursor: pointer;

        &::before {
            border-color: $secondary;
        }

        &::after {
            background-size: auto;
        }

        &.small {
            padding-top: 1/8 * 1rem;
        }
    }

    &.has-error .custom-control-label {
        &::before {
            border-color: $danger;
        }
    }
}

.form-validation-msg {
    display: none;

    .has-error & {
        display: block;
    }
}

.search-bar {
    .input-group-prepend {
        .btn {
            font-size: inherit;
            width: 56px;

            svg {
                width: 1em;
                height: 1em;
            }
        }
    }
}

.custom-control-star-rating {
    display: flex;
    align-items: center;

    button {
        background: transparent;
        padding: 0 0.25rem;
        border: none;
        color: $gray-300;

        &:hover,
        &:focus,
        &.hover,
        &.active {
            color: $primary;
        }
    }
}