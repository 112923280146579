.see-the-difference-slider {
    position: relative;

    .tns-item {
        transition-duration: 0ms !important;
        transition-delay: 500ms !important;

        @media print {
            &:not(.tns-slide-active) {
                display: none !important;
            }
        }
    }

    .tns-fadeOut {
        z-index: 0;

        .see-the-difference-slide__image {
            transform: translateX(-25px);
            opacity: 0;
        }

        &.tns-fadeToRight {
            .see-the-difference-slide__image {
                transform: translateX(25px);
            }
        }
    }

    .tns-fadeIn {
        .see-the-difference-slide__image {
            opacity: 1;
            transition-delay: 500ms;
        }

        .see-the-difference-slide__content > div {
            opacity: 1;
            transform: translateY(0);
            transition-duration: 750ms;
            transition-delay: 750ms;
        }
    }

    .see-the-difference-slide__image {
        opacity: 0;
        transition: opacity 500ms, transform 500ms;
    }

    .see-the-difference-slide__content {
        @include media-breakpoint-down(sm) {
            padding-bottom: 1rem;
        }

        @include media-breakpoint-down(xs) {
            padding-top: 9.5rem;
            padding-bottom: 3rem;
        }

        & > div {
            opacity: 0;
            transform: translateY(25px);
            transition: opacity 500ms, transform 500ms;
        }
    }
}

.see-the-difference-slide__content {
    position: relative;
    background-color: rgba(#fff,0.7);
    padding: 5rem 1.5rem;
    margin: -3rem 0.5rem 0;

    @include media-breakpoint-up(sm) {
        margin-right: 1.5rem;
        margin-left: 1.5rem;
    }

    @include media-breakpoint-up(md) {
        margin-right: 1rem;
        margin-left: 1rem;
    }

    @include media-breakpoint-up(lg) {
        margin-right: 1.5rem;
        margin-left: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
        padding-right: 2.25rem;
        padding-left: 2.25rem;
        margin-right: 2.5rem;
        margin-left: 2.5rem;
    }
}

.see-the-difference-slider-controls-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    @include media-breakpoint-up(xl) {
        left: 0.5rem;
    }
}

.see-the-difference-slider-controls {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding-bottom: 75%;

    button {
        top: 0;
        transform: none;

        &[data-controls="prev"] {
            left: -15px;

            @include media-breakpoint-up(xl) {
                left: -30px;
            }
        }

        &[data-controls="next"] {
            top: auto;
            right: -15px;
            bottom: 0;

            @include media-breakpoint-up(xl) {
                right: -30px;
            }
        }
    }
}

.see-the-difference-slider-nav {
    @include media-breakpoint-down(xs) {
        position: absolute;
        top: 75vw;
        right: 0;
        left: 0;
        z-index: 9;
        margin-top: 3rem;
    }
}
