.rating-tooltip {
    position: relative;
    left: 0;
    z-index: 1;

    @include media-breakpoint-up(sm) {
        left: 100px;
        max-width: 260px;
    }

    .rating-tooltip__arrow {
        top: -1.5rem;
        left: 50%;
        margin-left: -1.5rem;

        &,
        &::after {
            position: absolute;
            border: 1.5rem solid transparent;
            border-bottom-color: $border-color;
        }

        &::after {
            content: '';
            top: calc(1px - 1.5rem);
            left: -1.5rem;
            z-index: 1;
            border-bottom-color: #fff;
        }
    }
}
