.wbcf-image-slider,
.wbcf-text-slider {
    & > div:not(.tns-outer) {
        display: none;
    }

    .tns-item {
        transition-duration: 0ms !important;
        transition-delay: 500ms !important;

        @media print {
            &:not(.tns-slide-active) {
                display: none !important;
            }
        }
    }

    .tns-fadeOut {
        z-index: 0;
    }
}

.wbcf-image-slider {
    max-width: 400px;

    .tns-item {
        & > div {
            opacity: 0;
            transition: opacity 500ms, transform 500ms;
        }
    }

    .tns-fadeOut {
        & > div {
            transform: translateX(-25px);
            opacity: 0;
        }

        &.tns-fadeToRight {
            & > div {
                transform: translateX(25px);
            }
        }
    }

    .tns-fadeIn {
        & > div {
            opacity: 1;
            transition-delay: 500ms;
        }
    }
}

.wbcf-text-slider {
    .tns-item {
        min-height: 72px;

        & > div {
            opacity: 0;
            transform: translateY(25px);
            transition: opacity 500ms, transform 500ms;
        }
    }

    .tns-fadeIn {
        & > div {
            opacity: 1;
            transform: translateY(0);
            transition-duration: 750ms;
            transition-delay: 750ms;
        }
    }
}

.wbcf-slider-controls {
    @include media-breakpoint-up(sm) {
        position: absolute;
        top: 10%;
        bottom: 10%;
        left: 15px;
        max-width: 400px;
        width: calc(100% - 30px);
    }

    @include media-breakpoint-up(md) {
        width: calc(100% - 15px - 3rem);
    }

    button {
        top: 0;
        left: -10px;
        transform: none;

        &:first-child {
            @include media-breakpoint-up(lg) {
                left: 0;
            }
        }

        &:last-child {
            top: auto;
            right: -10px;
            bottom: 0;
            left: auto;

            @include media-breakpoint-up(lg) {
                right: 0;
            }
        }
    }
}
