// $enable-rounded: false;
// $enable-responsive-font-sizes: true;

$body-color: #7c7c7c;
$primary: #0C5CB1;
$secondary: #91CFE6;
$info: #367AC1;
$light: #F2F7FA;
$dark: #434343;
$text-muted: #B7B7B7;
$gray-200: #f0f0f0;
$gray-300: #dbdbdb;

$line-height-base: 1.6;

$btn-font-weight: 700;
$btn-font-family: montserrat, sans-serif;
$btn-padding-y: 15/16 * 1rem;
$btn-padding-x: 27/16 * 1rem;
$btn-border-radius: 0.125rem;
$btn-border-radius-lg: 0.125rem;
$btn-border-radius-sm: 0.125rem;

$input-height: 56/16 * 1rem;
$input-height-sm: 45/16 * 1rem;
$input-height-lg: 70/16 * 1rem;
$input-placeholder-color: #afafaf;
$input-background-color: #FCFDFD;
$input-border-color: #F0F0F0;
$input-border-radius: 0;

$custom-control-indicator-checked-color: $secondary;
$custom-control-indicator-checked-bg: #fff;
$custom-control-indicator-active-border-color: $secondary;
$custom-checkbox-indicator-border-radius: 0.25em;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='11.2' height='8' viewBox='0 0 14 10'%3e%3cpath fill='%23367ac1' d='M6.026,10,0,3.981,1.947,2.037,6.026,6.019,12.053,0,14,1.944Z'/%3e%3c/svg%3e");

$font-family-sans-serif: neue-haas-unica, sans-serif;
$font-family-serif: 'Tiempos Headline', serif;

$headings-font-weight: 600;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.25rem;
$display4-size: 2.5rem;

$display1-weight: 600;
$display2-weight: 600;
$display3-weight: 600;
$display4-weight: 600;