.hero {
    position: relative;
    display: flex;
    align-items: center;
    padding: 150px 0 calc(10vw + 50px);
    color: $white;
    text-shadow: 3px 3px 6px rgba($black,0.16);
    min-height: 100vh;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        position: fixed;
        top: 0;
        right: 68px;
        left: 0;
        min-height: 0;
        height: 630px;
        padding: 0;

        & + .page-content {
            margin-top: 630px;
        }
    }

    @media print {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        min-height: 0;
        height: auto !important;
        padding: 0 !important;
        background-color: transparent;

        & + .page-content {
            margin-top: 0 !important;
        }
    }

    a {
        color: #fff;

        &:hover {
            color: $secondary;
        }
    }

    .btn--arrow-link {
        color: #fff;

        &:hover,
        &:active {
            color: #fff;
        }
    }

    & + .page-content {
        padding-top: 6rem;

        @media print {
            padding-top: 3rem
        }
    }
}

.hero--full-height {
    @include media-breakpoint-up(md) {
        height: 100vh;
        padding: 100px 0 10vw;

        & + .page-content {
            margin-top: 100vh;
        }
    }
}

.hero__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $dark;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media print {
        display: none !important;
    }

    .vimeo-wrapper,
    .video-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;

        iframe,
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100vw;
            height: 56.25vw;
            min-height: 100vh;
            min-width: 177.77vh;
            border: none;
        }
    }
}

.hero__bg-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
    
    @media print {
        display: none !important;
    }
}

.hero__box {
    @include media-breakpoint-up(md) {
        min-height: 460px;
        max-width: 820px;
        background-color: rgba($black,0.35);
        
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            min-height: 0;
            padding-top: 5rem !important;
            padding-bottom: 5rem !important;
        }
    }

    @media print {
        min-height: 0;
        max-width: none;
        padding: 0 !important;
        margin: 0 !important;
        background-color: transparent;
    }
}

.hero-shape-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    @media print {
        display: none !important;
    }
}

@supports (-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0)) or (clip-path: polygon(0 0, 0 0, 0 0, 0 0)) {
    .hero-shape-overlay {
        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 50%;
            bottom: 0;
            left: 0;
            height: 10vw;
            background-color: #fff;
            clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
        }

        &::after {
            right: 0;
            left: 50%;
            clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%);
        }
    }

    .hero-shape-overlay--one-third {
        &::before {
            right: 66.667%;
        }

        &::after {
            left: 33.333%;
        }
    }

    .hero-shape-overlay--two-thirds {
        &::before {
            right: 33.333%;
        }

        &::after {
            left: 66.667%;
        }
    }
}